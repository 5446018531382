<template>
  <b-col cols="12" md="4" lg="3">
    <b-card class="agent-card d-flex flex-column" no-body>
      <div class="position-relative image-container">
        <!-- Agent's Image -->
        <b-carousel
          id="carousel-agent-images"
          v-model="carouselIndex"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
        >
          <!-- Loop through imageUrls array -->
          <b-carousel-slide
            v-for="(image, index) in agent.imageUrls"
            :key="index"
            :img-src="image"
          ></b-carousel-slide>
        </b-carousel>

        <div class="position-absolute top-right-icons">
          <!-- Remove Button (only if agent is saved) -->
          <div 
            v-if="isSaved"
            class="top-right-icon remove-icon"
            @click="removeAgent">
            <feather-icon size="24" icon="XIcon" fill="red" />
          </div>

          <!-- Share Button -->
          <div 
            v-if="showShareButton" 
            class="top-right-icon share-icon"
            @click="toggleShareOptions">
            <feather-icon size="24" icon="Share2Icon" />
          </div>
        </div>
      </div>

      <!-- Agent Information -->
      <b-card-body class="d-flex flex-column p-2">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Agent's Name -->
          <b-card-title class="agent-name mb-0">{{ agent.name }}</b-card-title>

          <!-- Rating -->
          <div class="agent-rating d-flex align-items-center">
            <feather-icon icon="StarIcon" class="mr-1" :style="{ color: 'Red' }" />
            <span :style="{ color: 'Red' }">{{ agent.rating }}</span>
          </div>
        </div>

        <!-- Agent's Headline -->
        <b-card-sub-title class="agent-headline">{{ agent.headline }}</b-card-sub-title>

        <!-- Execution Count -->
        <div class="d-flex align-items-center mt-2">
          <feather-icon icon="PlayCircleIcon" class="mr-1" :style="{ color: 'DarkGreen' }" />
          <span :style="{ color: 'DarkGreen' }">{{ displayExecutionCount }} Runs</span>
        </div>
      </b-card-body>

      <!-- Run Button -->
      <b-card-body class="d-flex flex-column p-0">
        <b-button 
          block 
          :disabled="agent.agentStatus === 'Inactive'" 
          :variant="agent.agentStatus === 'Inactive' ? 'primary' : 'primary'" 
          class="cart-btn no-border-radius"
          @click="runAgent(agent.agentId)">
          <feather-icon 
            :icon="agent.agentStatus === 'Inactive' ? 'ZapIcon' : 'PlayIcon'" 
            class="mr-25" 
          />
          <!-- Button text based on if the agent is saved -->
          {{ runButtonText }}
        </b-button>
      </b-card-body>

      <!-- Agent Image Modal -->
      <b-modal v-model="showModal" hide-header hide-footer centered size="med" no-close-on-backdrop backdrop-dismissable @hidden="showModal = false" content-class="p-0" dialog-class="modal-90w modal-no-margin" body-class="p-0">
        <div class="modal-close-container">
          <button type="button" class="close clear-button" @click="showModal = false">&times;</button>
        </div>
        <img :src="computedAgentImageUrl" class="w-100 h-auto" />
      </b-modal>
    </b-card>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive';

export default {
  name: 'AgentCardComponent',
  props: {
    agent: {
      type: Object,
      required: true,
    },
    showShareButton: {
      type: Boolean,
      default: true,
    },
    isSaved: {
      type: Boolean,
      default: false, // Default to false if not passed
    },
    showUserExecutionCount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      showShareOptions: false,
      carouselIndex: 0,
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    computedAgentImageUrl() {
      return this.agent.imageUrl || '/default-agent-image.jpg';
    },
    runButtonText() {
      if (this.agent.agentStatus === 'Inactive') {
        return 'IN TRAINING';
      }
      if (this.agent.userAgentExecutionCount > 0 || this.isSaved) {
        return 'RUN AGAIN';
      }
      return 'RUN';
    },
    displayExecutionCount() {
      // Check if you need to show user-specific execution count or overall execution count
      return this.showUserExecutionCount 
        ? this.agent.userAgentExecutionCount || 0  // Fallback to 0 if undefined
        : this.agent.executionCount || 0;         // Fallback to 0 if undefined
    }
  },
  methods: {
    removeAgent() {
      this.$emit('remove-agent', this.agent.agentId);
    },
    toggleShareOptions() {
      this.showShareOptions = !this.showShareOptions;
    },
    runAgent(agentId) {
      if (this.agent && this.agent.apiEndpoint) {
        this.$router.push(this.agent.apiEndpoint)
          .catch(err => {
            console.error('Navigation error:', err);
          });
      } else {
        console.error('No API endpoint provided.');
      }
    }
  },
};
</script>
  
  <style scoped>
  /* Update styles for agents */
  .agent-card {
    margin-bottom: 1rem;
  }
  
  .agent-list-img-object-fit {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  
  .agent-name {
    font-weight: normal;
    font-size: 1.25rem;
    margin-top: -05px;
  }
  
  .agent-headline {
  font-size: 1rem;
  color: rgb(118, 116, 116)  !important;/* Dark grey */
  margin-top: 5px !important;
  margin-bottom: -15px
}

  
  .top-right-icons {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .top-right-icon {
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .share-options {
    display: flex;
    flex-direction: column;
  }
  
  .b-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .b-card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  
  /* Button styling */
  .cart-btn {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
  
  .no-border-radius {
    border-radius: 0 !important;
  }
  
  .modal-90w {
    max-width: 90%;
  }
  
  .modal-no-margin .modal-dialog {
    margin: 0 !important;
  }
  
  .modal-no-margin .modal-content {
    border: none !important;
  }
  
  .modal-close-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1051;
  }
  
  .clear-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    opacity: 0.8;
  }
  
  .clear-button:hover {
    opacity: 1;
  }
  
  .love-icon, .share-icon {
    width: auto;
    height: auto;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255, 255, 255);
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-radius: 55%;
  }
  
  .filled-heart {
    color: red !important;
  }
  
  .empty-heart {
    color: rgb(0, 0, 0) !important;
  }

  .agent-rating {
  margin-left: auto; 
}

.agent-stats {
  font-size: 0.875rem;
}


.feather-icon {
  vertical-align: middle;
}
  
  </style>
  