<template>
  <div>
    <div class="main-loader" v-if="isLoading"></div>
    <div class="agents-page" v-else>
      <b-container>
        <b-row>
          <swiper :options="swiperOptions" class="swiper-container mt-1"></swiper>
        </b-row>

        <!-- Agent Cards -->
        <b-row v-if="agents.length">
          <agent-card 
              v-for="agent in paginatedAgents"
              :key="agent.agentId"
              :agent="agent"
              :isSaved="savedAgents.includes(agent.agentId)"
              :showShareButton="false"
              @run-agent="runAgent(agent.agentId)"
              :showUserExecutionCount="true"
            />
        </b-row>

        <!-- No data found message -->
        <div class="no-data mt-5 mb-5" v-else>
          <div class="mx-auto text-center">
            <h2>Initiate running agents from the Explore Agents page, and they will be listed here for future runs.</h2>
          </div>
        </div>

        <!-- Infinite Scroll Trigger -->
        <div ref="loadMoreTrigger" class="infinite-scroll-trigger"></div>
      </b-container>
    </div>
  </div>
</template>

<script>
import agentCard from './agentCard.vue'; 
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import axios from 'axios';

export default {
  name: 'AgentsPage',
  components: {
    Swiper,
    SwiperSlide,
    agentCard,
  },
  data() {
    return {
      isLoading: false,
      agents: [], 
      savedAgents: [],
      agentsPerPage: 10, 
      currentPage: 1,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 8,
      }
    };
  },
  computed: {
    paginatedAgents() {
      return this.agents.slice(0, this.agentsPerPage * this.currentPage);
    }
  },
  async created() {
    await this.fetchAgentsAndSaved(); // Combined API call
    // this.generateTabs(); // Create the tabs dynamically based on agents
    this.isLoading = false; // Loading completed
  },
  methods: {
    async fetchAgentsAndSaved() {
      this.isLoading = true;
      try { 
        const userData = JSON.parse(localStorage.getItem('userData')); // Parse userData only once
        const userId = userData.userId;

        // Fetch agents and saved agents from the same API call
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}user/savedAgents/${userId}`);
        this.agents = response.data.data || []; // Ensure data is an array
        this.savedAgents = Array.isArray(response.data.savedAgents) 
          ? response.data.savedAgents 
          : []; // Ensure savedAgents is an array

      } catch (error) {
        console.error('Error fetching agents:', error);
        this.agents = [];
        this.savedAgents = [];
      } finally {
        this.isLoading = false;
      }
    },

    runAgent(agentId) {
      this.$router.push(`/agents/${agentId}`);
    },

    removeAgent(agentId) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const updatedSavedAgents = userData.savedAgents.filter(id => id !== agentId);

      localStorage.setItem('userData', JSON.stringify({
        ...userData,
        savedAgents: updatedSavedAgents
      }));

      this.agents = this.agents.filter(agent => agent.agentId !== agentId);
    },
  },
};
</script>


  
  <style scoped>  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: auto;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    margin-top: 15px;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  
  .tab-button {
    margin: 0 5px;
  }
  
  .infinite-scroll-trigger {
    width: 100%;
    height: 50px;
  }
  /* Swiper and tab styles */
.swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}

.swiper-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 15px !important;
  padding-bottom: 10px !important;
}

.swiper-container::-webkit-scrollbar {
  display: none;
}

.tab-button {
  font-size: 1rem !important;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0.48571rem 0.71429rem !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: hsl(0, 0%, 100%);
  transition: all 0.2s ease-in-out;
}

.tab-button:hover,
.tab-button.is-active {
  background-color: #0069d9;
  color: white;
}

.b-button {
  border-radius: 0.25rem;
}

.ss-width {
  width: auto !important;
}
.agents-page {
  margin-left: auto;
  margin-right: auto;
}
.b-container {
  padding: 0 20px; /* Padding for the container to align content nicely */
}

.no-data {
  text-align: center;
  padding: 20px;
}

  </style>
  